<template>
  <v-col>
    <v-row>
      <v-menu
        ref="menuDateTo"
        v-model="menuDateTo"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        :readonly="readonly"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="form.date_to | dateFormat"
            :label="`${$t('INHABITANTS.DATE_TO')} *`"
            append-icon="mdi-calendar"
            clearable
            autocomplete="nope"
            role="button"
            :error-messages="errors.date_to"
            readonly
            @click:clear="clear_to"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          ref="picker"
          v-model="form.date_to"
          :min="form.date_from"
          first-day-of-week="1"
          @change="saveDateto"
        ></v-date-picker>
      </v-menu>
    </v-row>
    <v-row>
      <v-textarea
        v-model="form.info"
        :label="$t('PROFILE.ADDITIONAL_INFO')"
        :counter="1500"
        :rules="rules.textDescription"
        rows="4"
        :error-messages="errors.info"
        @input="updateValue('info', $event)"
      ></v-textarea>
    </v-row>
  </v-col>
</template>

<script>
import formEditable from '@/mixins/form-editable';
import { dateFormat } from '@/filters';
import { maxLength } from '@/rules';

export default {
  name: 'Checkout',

  mixins: [formEditable],

  filters: {
    dateFormat,
  },

  data: () => {
    return {
      valid: true,
      form: {},
      rules: {
        textDescription: [maxLength(1500)],
      },
      menuDateTo: false,
    };
  },

  created() {},

  methods: {
    clear_to() {
      this.form.date_to = null;
    },

    saveDateto(date) {
      this.$refs.menuDateTo.save(date);
      this.updateValue('date_to', date);
    },
  },
};
</script>
<style lang="scss">
.checkout-sheet {
  & &__item {
    display: block;
    margin-bottom: 0.3rem;

    & &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
