<template>
  <v-card class="checkout-sheet" flat dense>
    <v-card-title v-if="!!Object.keys(form).length">
      <div class="d-inline-flex align-baseline">
        <span class="text-h5"
          >{{ $t('ITEM.EDIT') }}
          {{ $t('INHABITANT_CHANGES.REQUEST').toLowerCase() }}</span
        >
      </div>
      <v-spacer></v-spacer>
      <div class="d-inline-flex align-baseline">
        <v-select
          v-model="form.status_id"
          solo
          flat
          :items="statuses"
          item-value="id"
          item-text="name"
          small-chips
          hide-details
          dense
          style="width: 136px"
          @change="updateValue('status_id', $event)"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              small
              :color="item.color"
              dark
              class="ml-1"
              v-bind="attrs"
              :input-value="selected"
              @click="select"
            >
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>
      </div>
      <v-btn icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text dense>
      <checkout-info
        :person="form.person"
        :apartment="form.apartment"
      ></checkout-info>
      <v-form ref="form" v-model="isValid">
        <checkout
          v-model="form.data.inhabitant"
          :errors="errors.inhabitant"
          @update-prop="updateValue(`inhabitant.${$event}`, value)"
        >
        </checkout>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <slot name="buttons" />
    </v-card-actions>
  </v-card>
</template>

<script>
import formEditable from '@/mixins/form-editable';
import Checkout from '@/modules/inhabitant_changes/components/forms/Checkout';
import CheckoutInfo from '@/modules/inhabitant_changes/components/forms/CheckoutInfo';
import { mapCacheActions } from 'vuex-cache';
import i18nRefreshData from '@/mixins/i18nRefreshData';
import { APPROVED } from '@/modules/inhabitant_changes/common/statuses';

export default {
  name: 'EditCheckoutCard',
  components: { CheckoutInfo, Checkout },

  mixins: [formEditable, i18nRefreshData],

  data: () => ({
    form: {},
    isValid: true,
    statuses: [],
  }),

  watch: {
    'form.person.inhabitant.id': {
      immediate: true,
      handler: 'copyData',
    },
    isValid() {
      this.$emit('isValid', this.isValid);
    },
  },

  created() {
    this.refreshData();
  },

  methods: {
    ...mapCacheActions('inhabitant_change', ['getStatuses']),

    refreshData() {
      this.getStatuses().then(({ data }) => {
        this.statuses = data.map((el) => {
          return el.id === APPROVED ? { ...el, disabled: true } : el;
        });
      });
    },

    updateValue(key, value) {
      this.emitter();
      this.updateProp(key, value);
    },

    cancel() {
      this.$emit('cancel');
    },
    copyData(id) {
      this.form.data.inhabitant.date_from =
        this.form.person.inhabitant.date_from;
      this.updateValue(
        `data.inhabitant.date_from`,
        this.form.person.inhabitant.date_from
      );

      this.form.data.inhabitant.inhabitant_id = id;
      this.updateValue(`data.inhabitant.inhabitant_id`, id);
    },
  },
};
</script>

<style scoped></style>
