<template>
  <div>
    <v-card tile flat elevation="0" color="grey lighten-4 mb-6">
      <v-card-text dense class="grey--text text--darken-3">
        <p class="checkout-sheet__item">
          <strong> {{ $t('INHABITANT_CHANGES.CUSTOMER') }}: </strong>
          <span>{{ person.first_name + ' ' + person.last_name }}</span>
        </p>

        <p class="checkout-sheet__item">
          <strong> {{ $t('ITEM.ADDRESS') }}: </strong>
          <span>
            {{ address }}
          </span>
        </p>

        <p class="checkout-sheet__item">
          <strong> {{ $t('PROFILE.PHONE') }}: </strong>
          <span>{{ person.personal_profile.phone }}</span>
        </p>

        <p class="checkout-sheet__item">
          <strong> {{ $t('PROFILE.MOBILE_PHONE') }}: </strong>
          <span>{{ person.personal_profile.mobile_phone }}</span>
        </p>

        <p class="checkout-sheet__item">
          <strong> {{ $t('PROFILE.EMAIL') }}: </strong>
          <span>{{ person.user.email }}</span>
        </p>

        <p class="checkout-sheet__item">
          <strong> {{ $t('BUILDING_COMPLEXES.ITEM') }}: </strong>
          <span>{{ apartment.building.building_complex.name }}</span>
        </p>

        <p class="checkout-sheet__item">
          <strong> {{ $t('APARTMENTS.ITEM') }}: </strong>
          <span>{{ apartment.name }}</span>
        </p>
      </v-card-text>
    </v-card>
    <v-col>
      <v-row>
        <v-text-field
          :value="person.inhabitant.date_from | dateFormat"
          :label="`${$t('INHABITANTS.DATE_FROM')} *`"
          append-icon="mdi-calendar"
          clearable
          autocomplete="nope"
          role="button"
          readonly
          disabled
        ></v-text-field>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { dateFormat } from '@/filters';

export default {
  name: 'CheckoutInfo',
  props: {
    person: {
      type: Object,
      default: () => ({ personal_profile: {} }),
    },
    apartment: {
      type: Object,
      default: () => ({ building: { building_complex: {} } }),
    },
  },
  filters: {
    dateFormat,
  },
  computed: {
    address() {
      if (!this.person.personal_profile) {
        return '';
      }
      const street = this.person.personal_profile.street || '';
      const zip = this.person.personal_profile.zip || '';
      const city = this.person.personal_profile.city || '';
      return `${street}, ${zip} ${city}`.replace(/^[\s,]+|[\s,]+$/g, '');
    },
  },
};
</script>

<style scoped></style>
